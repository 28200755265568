/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    code: "code",
    pre: "pre",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Many of the texts in the Signatu UI can be customized to your needs. However, note that\nchanging the texts may have legal consequences, for instance if you add texts\nthat mislead the user. If you are uncertain about the texts,\ncontact us at ", React.createElement(_components.a, {
    href: "hello@signatu.com"
  }, "hello@signatu.com"), "."), "\n", React.createElement(_components.h2, {
    id: "tag-sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tag-sdk",
    "aria-label": "tag sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tag SDK"), "\n", React.createElement(_components.p, null, "In the Tag SDK you can provide texts in the app parameters using the optional ", React.createElement(_components.code, null, "texts"), " parameter\nin the tag, e.g., for the cookie button tag: ", React.createElement(React.Fragment, null, packageJSON.version)), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "<script type=\"text/javascript\">\n    (e=>{var t=window.Signatu=window.Signatu||{_c:[]};if(!t.init){t.init=function(){for(var e,t=arguments.length,n=new Array(t),a=0;a<t;a++)n[a]=arguments[a];return null===(e=window.Signatu&&window.Signatu._c)||void 0===e?void 0:e.push([\"init\",n])};var n=document.createElement(\"script\");n.type=\"text/javascript\",n.async=!0,n.src=(e.baseUrl?e.baseUrl:\"https://cdn.signatu.com/sdk/\"+e.version)+\"/shim.js\",document.getElementsByTagName(\"head\")[0].appendChild(n),t.init(e)}})({\n     // ...\n     \"app\": \"cookie-button\",\n     \"texts\": {\n        \"en\": {\n            \"Accept\": \"Got it\",\n            \"AcceptAll\": \"Sure\",\n            \"Reject\": \"Don't bother me\",\n            \"RejectAll\": \"No way!!!\",\n            \"ReadMore\": \"Read more\",\n        }\n     }\n})\n</script>\n")), "\n", React.createElement(_components.h2, {
    id: "react-sdk",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#react-sdk",
    "aria-label": "react sdk permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "React SDK"), "\n", React.createElement(_components.p, null, "If React, you can override the text by using the ", React.createElement(_components.code, null, "overrideTexts"), " function from ", React.createElement(_components.code, null, "@signatu/i18n"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "import { overrideTexts } from '@signatu/i18n'\n\noverrideTexts({\n    en: {\n        Accept: 'Got it',\n        AcceptAll: 'Sure',\n        Reject: \"Don't bother me\",\n        RejectAll: 'No way!!!',\n    },\n})\n")), "\n", React.createElement(_components.p, null, "The parameter is a map of ISO 639-1 language codes containing the keys you want to override."), "\n", React.createElement(_components.h2, {
    id: "text-keys",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#text-keys",
    "aria-label": "text keys permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Text keys"), "\n", React.createElement(_components.p, null, "Almost all of the texts in the standard Signatu UI components can be customized. We list the most common keys here.\nIf you need additional customization, please contact us at ", React.createElement(_components.a, {
    href: "hello@signatu.com"
  }, "hello@signatu.com"), " to discuss."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "Accept"), ": Accept button text"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "AcceptAll"), ": Accept all button text"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "Reject"), ": Decline button text"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "RejectAll"), ": Reject all button text"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "ReadMore"), ": Read more button text"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "CustomizePreferences"), ": Settings button text"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 24.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVQY03WN0Q7CIAxF/f/PJHGDbRCwlEIpYNyyOKOeh5s26bm9KaXmeSaiscPM3nsACCEcGU967+OT23HUWhtj9N5FpHzBO/3kLV+bSik5U8mUcyZKRAkR875QQkqYEI83P2TmEh7RrA5j3KybzKq1BgC9WjUtd2OXzf2Va+VI1aJIZSzyGkSY2aEs0AyIx3qVn2FwIzf3qrAKAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Example text customization\"\n        title=\"Example text customization\"\n        src=\"/static/13f73c1e658d8c8b9468f27895766b21/7842b/accept-reject.png\"\n        srcset=\"/static/13f73c1e658d8c8b9468f27895766b21/f8f3a/accept-reject.png 200w,\n/static/13f73c1e658d8c8b9468f27895766b21/6a8a8/accept-reject.png 400w,\n/static/13f73c1e658d8c8b9468f27895766b21/7842b/accept-reject.png 800w,\n/static/13f73c1e658d8c8b9468f27895766b21/77308/accept-reject.png 881w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 24%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVQY01XQTUoEMRAF4L7aXEBwP6BuBbcDehdv4KzEhXMOt0MqMemkCdhx0qHzV4l0gqAfWTwehCpqUEoJITjnjLGUUq21/NeaGiL+fT5kH+IgmrERQsim562XstZ6Zubq/vX64e3u6XT7eNof3nc3x+eXj+GzYU3PANAbAOCc55xiwnG6CDkrbSdtRzVTPpvLOhBCKKUAQAiBX5RSxhghxBhT2965FP3tEMuay2SWlEIpZZuslJJSaq2XxlrrnOvBe98/rxG/1poQXchL3O6CiD8Jig+gixn/vwAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Example text customization\"\n        title=\"Example text customization\"\n        src=\"/static/bcff4f23ec4fbe77d56cb345d3a1e72d/7842b/accept-reject-all.png\"\n        srcset=\"/static/bcff4f23ec4fbe77d56cb345d3a1e72d/f8f3a/accept-reject-all.png 200w,\n/static/bcff4f23ec4fbe77d56cb345d3a1e72d/6a8a8/accept-reject-all.png 400w,\n/static/bcff4f23ec4fbe77d56cb345d3a1e72d/7842b/accept-reject-all.png 800w,\n/static/bcff4f23ec4fbe77d56cb345d3a1e72d/1995d/accept-reject-all.png 942w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
